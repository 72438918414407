import React from "react";

const Header = () => {
  return (
    <div className="shadow">
      <div className="container flex items-center justify-between gap-5 py-3">
        <div>
          <img
            onClick={() =>
              window.open("https://iwsonlineschool.co.uk/", "_blank")
            }
            className="object-cover h-12 cursor-pointer"
            src="IWS_Logo.webp"
            alt="logo"
          />
        </div>
        <div>
          <div
            onClick={() =>
              window.open("https://iwsonlineschool.co.uk/contact-us", "_blank")
            }
            className="bg-[#4366F6] rounded-lg px-6 py-2 text-white cursor-pointer text-sm"
          >
            Contact Us
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
