import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import DashLayout from "./layout/DashLayout";
import IntroductionPage from "./pages/IntroductionPage";
import ParentPage from "./pages/ParentPage";
import ConfirmPage from "./pages/ConfirmPage";
import ScrollToTop from "./lib/ScrollToTop";
import StudyPage from "./pages/StudyPage";
import StudentPage from "./pages/StudentPage";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<DashLayout />}>
          <Route index element={<IntroductionPage />} />

          <Route path="study" element={<StudyPage />} />
          <Route path="parent" element={<ParentPage />} />
          <Route path="student" element={<StudentPage />} />
          <Route path="confirm" element={<ConfirmPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
