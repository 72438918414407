import React from 'react'
import Header from '../components/Header'
import { Outlet } from 'react-router-dom'


const DashLayout = () => {
  return (
    <>
        <Header />

        <div className='container max-w-[685px] w-full pt-6 pb-14'>
   
        <Outlet />

        </div>
    </>
  )
}

export default DashLayout